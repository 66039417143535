import { SPECIAL_PRODUCT_TYPES } from 'swag-common/constants/product.constants';
import { isBoxType } from 'swag-common/utils/custom-store-product/product-is-box-type.util';
import { isBundle } from 'swag-common/utils/custom-store-product/product-is-bundle.util';
import { checkIsBundleAddon } from 'swag-client-common/utils/product.utils';
import { isSwagBox } from './check-product-available-for-bundle.utils';
export const validateCountOfAddonsForBundleCreation = selectedProductsToCreate => {
  const packages = selectedProductsToCreate.filter(product => {
    const variants = product.variants || [];
    const variant = variants[0];
    return !isBundle(variant) && isBoxType(product.swagProductId);
  });
  const notecards = selectedProductsToCreate.filter(product => {
    var _product$swagProductI;
    return ((_product$swagProductI = product.swagProductId) === null || _product$swagProductI === void 0 ? void 0 : _product$swagProductI.specialProductType) === SPECIAL_PRODUCT_TYPES.BOX_NOTECARD;
  });
  return !(packages.length > 1 || notecards.length > 1);
};
export const findForbiddenProductsForBundleCreation = selectedProductsToCreate => {
  return selectedProductsToCreate.filter(product => product.psProduct && !product.psProduct.isAvailableForPackagedBundle);
};
export const isJustAddonsSelected = selectedProductsToCreate => {
  return selectedProductsToCreate.every(product => checkIsBundleAddon(product));
};
export const BUNDLE_CREATE_VALIDATIONS_ERRORS = {
  BOX_IN_BOX: 'You cannot create a box using another box.',
  FORBIDDEN_PRODUCTS: 'These products cannot be added to a box:',
  TWO_ADDONS: 'You cannot create a box with more than one notecard or package.',
  JUST_ADDONS: 'You cannot create a box with just add-ons.'
};
export const validateBundleCreation = selectedProductsToCreate => {
  const forbiddenProducts = findForbiddenProductsForBundleCreation(selectedProductsToCreate);
  if (forbiddenProducts.length) {
    const productsNames = forbiddenProducts.map(product => product.name).join(', ');
    return {
      error: `${BUNDLE_CREATE_VALIDATIONS_ERRORS.FORBIDDEN_PRODUCTS} ${productsNames}.`
    };
  }
  const someProductsAreBoxes = selectedProductsToCreate.some(product => isSwagBox(product) || isBundle(product.variants[0]));
  if (someProductsAreBoxes) {
    return {
      error: BUNDLE_CREATE_VALIDATIONS_ERRORS.BOX_IN_BOX
    };
  }
  if (!validateCountOfAddonsForBundleCreation(selectedProductsToCreate)) {
    return {
      error: BUNDLE_CREATE_VALIDATIONS_ERRORS.TWO_ADDONS
    };
  }
  if (isJustAddonsSelected(selectedProductsToCreate)) {
    return {
      error: BUNDLE_CREATE_VALIDATIONS_ERRORS.JUST_ADDONS
    };
  }
};