function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { getCSVFileLink } from 'swag-common/services/links/get-download-link-from-s3.service';
import { INVENTORY_TABLE_ROWS_PER_PAGE } from 'swag-common/constants/inventory';
import { getShipoutProductsDetails } from 'swag-common/business-logic/inventory/get-shipout-products-details.logic';
import { getInventoryProductsDetails } from 'swag-common/business-logic/inventory/get-distribution-products-details.logic';
import { SHIPOUT_STATUSES, SHIPOUT_STATUSES_SEQUENCE, RECIPIENTS_LARGE_DIGITS_NUMBER } from 'swag-common/constants/custom-store-shipouts.constants';
import { selectedColorsSelector, selectedProductsSelector } from '../products/products.selectors';
import { isLoadedInventoryEnteredSelector } from '../inventory/inventory.selectors';
import { advancedDistributionsSelector } from '../advanced-distributions/advanced-distributions.selectors';
import { calculateShipoutsData, fulfillWithShippingDetails, generateCsvTemplateData } from './store-shipouts.selectors.utils';
export const selectedProductsToCsvTemplateSelector = createSelector(selectedProductsSelector, selectedColorsSelector, generateCsvTemplateData);
const storeShipoutsSelector = state => state.shipouts;
export const pfiShipoutsSelector = createSelector(storeShipoutsSelector, shipouts => shipouts.pfi);
export const csvShipoutsSelector = createSelector(storeShipoutsSelector, shipouts => shipouts.csv);
export const getShipoutByIdSelector = createSelector(csvShipoutsSelector, (_, shipoutId) => shipoutId, (shipouts, shipoutId) => shipouts.find(shipout => shipout._id === shipoutId));
export const selectedShipoutIdSelector = createSelector(storeShipoutsSelector, shipouts => shipouts.selectedShipoutId);
export const shipoutsCalculatedDataPfiSelector = createSelector(pfiShipoutsSelector, calculateShipoutsData);
export const shipoutsCalculatedDataSelector = createSelector(csvShipoutsSelector, calculateShipoutsData);
export const fulfilledWithShippingDetailsPFIShipoutsSelector = createSelector(shipoutsCalculatedDataPfiSelector, fulfillWithShippingDetails);
export const fulfilledWithShippingDetailsCSVShipoutsSelector = createSelector(shipoutsCalculatedDataSelector, fulfillWithShippingDetails);
export const isShipoutsLoadedSelector = createSelector(storeShipoutsSelector, shipouts => shipouts.isLoaded);
export const shipoutPageSelector = createSelector(storeShipoutsSelector, shipouts => shipouts.page);
export const shipoutTotalSelector = createSelector(storeShipoutsSelector, shipouts => shipouts.total);
export const isShipoutPaginationShownSelector = createSelector(shipoutTotalSelector, total => total > INVENTORY_TABLE_ROWS_PER_PAGE);
export const isShipoutsLoadedWithEnteredSelector = createSelector(isShipoutsLoadedSelector, isLoadedInventoryEnteredSelector, (shipoutsLoaded, enteredLoaded) => shipoutsLoaded && enteredLoaded);
export const fulfilledWithShippingDetailsCSVFileShipoutsSelector = createSelector(fulfilledWithShippingDetailsCSVShipoutsSelector, shipouts => shipouts.map(shipout => {
  const {
    shipmentCalculationErrorReceiversNumber,
    status,
    paidAt,
    products
  } = shipout;
  const isError = shipmentCalculationErrorReceiversNumber > 0 && status === SHIPOUT_STATUSES.SHIPMENT_CALCULATED;
  const isShipmentCsvCanBeDownloaded = SHIPOUT_STATUSES_SEQUENCE.indexOf(status) >= SHIPOUT_STATUSES_SEQUENCE.indexOf(SHIPOUT_STATUSES.SHIPMENT_CALCULATED) && !isError;
  return _objectSpread({}, shipout, {
    isError,
    isShipmentCsvCanBeDownloaded,
    isPaid: Boolean(paidAt),
    csvUrl: getCSVFileLink(shipout.csvUrl),
    productsToShow: getInventoryProductsDetails(products)
  });
}));
export const isShipoutsHasLargeReceiversNumber = createSelector(fulfilledWithShippingDetailsCSVShipoutsSelector, shipouts => {
  const shipoutReceiversNumber = shipouts.map(shipout => shipout.receivers.length);
  const hasLargeNumber = shipoutReceiversNumber.some(receiversNumber => String(receiversNumber).length > RECIPIENTS_LARGE_DIGITS_NUMBER);
  return hasLargeNumber;
});
export const isEmptyFulfilledWithShippingDetailsPfiShipoutsSelector = createSelector(fulfilledWithShippingDetailsPFIShipoutsSelector, pfi => !pfi.length);
export const isEmptyFulfilledWithShippingDetailsCsvShipoutsSelector = createSelector(fulfilledWithShippingDetailsCSVShipoutsSelector, csv => !csv.length);
export const selectedShipoutSelector = createSelector(pfiShipoutsSelector, csvShipoutsSelector, selectedShipoutIdSelector, (pfi, csv, selectedShipoutId) => pfi.find(({
  _id
}) => _id.toString() === selectedShipoutId.toString()) || csv.find(({
  _id
}) => _id.toString() === selectedShipoutId.toString()));
export const selectedShipoutProductsSelector = createSelector(selectedShipoutSelector, shipout => shipout ? shipout.products : []);
export const shipoutVariantsByColorSelector = createSelector(selectedShipoutProductsSelector, variants => getShipoutProductsDetails(variants));
export const haveUpcomingDistributionsForSelectedShipoutSelector = createSelector(advancedDistributionsSelector, selectedShipoutIdSelector, (distributions, selectedShipoutId) => Boolean(distributions.filter(({
  distributionId
}) => distributionId !== selectedShipoutId).length));