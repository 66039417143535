export const calculateMaxPossibleQuantityOfBundles = (data, includeLimitingProductDetailsInResult = false) => {
  let availableToSend = 0;
  let availableToSendReserve = 0;
  let inStock = 0;
  let inReserveProductionBundleInitial = Infinity;
  let inReserveProductionBundle = 0;
  let bundleLimitingProduct = null;
  let bundlesQuantityUpdated = false;
  for (const item of data) {
    const {
      productId,
      quantityPerBundle,
      inStockQuantity,
      inProductionQuantity,
      inStockReservedQuantity = 0,
      inProductionReservedQuantity = 0,
      inReserveStockBundleQuantity = 0,
      inReserveProductionBundleQuantity = 0,
      colorValue
    } = item;
    const availableInStockForCurrentItem = inStockQuantity - inStockReservedQuantity;
    const availableInProductionForCurrentItem = inProductionQuantity - inProductionReservedQuantity;
    const totalAvailableForCurrentItem = availableInStockForCurrentItem + availableInProductionForCurrentItem;
    const totalBundlesPossible = Math.floor(totalAvailableForCurrentItem / quantityPerBundle);
    if (!bundlesQuantityUpdated || totalBundlesPossible < availableToSend) {
      availableToSend = totalBundlesPossible;
      if (includeLimitingProductDetailsInResult) {
        bundleLimitingProduct = {
          productId: productId || '',
          inStockAvailableQuantity: availableInStockForCurrentItem,
          inProductionAvailableQuantity: availableInProductionForCurrentItem,
          colorValue
        };
      }
    }
    const totalAvailableForCurrentItemReserve = inReserveStockBundleQuantity + inReserveProductionBundleQuantity;
    const totalBundlesPossibleReserve = Math.floor(totalAvailableForCurrentItemReserve / quantityPerBundle);
    if (!bundlesQuantityUpdated || totalBundlesPossibleReserve < availableToSendReserve) {
      availableToSendReserve = totalBundlesPossibleReserve;
    }
    const maxInStockBundlesForCurrentItem = Math.floor(availableInStockForCurrentItem / quantityPerBundle);
    if (!bundlesQuantityUpdated || maxInStockBundlesForCurrentItem < inStock) {
      inStock = maxInStockBundlesForCurrentItem;
    }
    const inProductionReserveBundleStockPossible = Math.floor(inReserveProductionBundleQuantity / quantityPerBundle);
    if (inProductionReserveBundleStockPossible < inReserveProductionBundleInitial && inProductionReserveBundleStockPossible > 0) {
      inReserveProductionBundleInitial = inProductionReserveBundleStockPossible;
      inReserveProductionBundle = inProductionReserveBundleStockPossible;
    }
    bundlesQuantityUpdated = true;
  }
  const inProduction = availableToSend - inStock;
  const inReserveStockBundle = availableToSendReserve - inReserveProductionBundle;
  return {
    availableToSend,
    inStock,
    inProduction,
    bundleLimitingProduct,
    inReserveStockBundle,
    inReserveProductionBundle
  };
};