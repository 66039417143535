function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? Object(arguments[i]) : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys.push.apply(ownKeys, Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { createSelector } from 'reselect';
import { calculateMaxPossibleQuantityOfBundles } from 'swag-common/utils/swag-bundles/calculate-max-possible-quantity-of-bundles.util';
import { preSelectedPackagingProductSelector, preSelectedNotecardProductSelector } from '../swag-bundles.selectors';
import { swagBundlesSelectedProductsCommonSizesSelector, getAllSelectedApparelsSpecificSizeSelector, swagBundlesSelectedProductsOnlyNonApparelsSelector, swagBundlesSelectedProductsOnlyApparelsSelector } from './swag-bundles-selected-variants-with-quantity.selectors';
export const commonSelectedProductsSizesWithCalculatedQuantitiesSelector = createSelector(swagBundlesSelectedProductsCommonSizesSelector, state => state, (bundleSizes, state) => {
  if (!(bundleSizes !== null && bundleSizes !== void 0 && bundleSizes.length)) {
    return [];
  }
  return bundleSizes === null || bundleSizes === void 0 ? void 0 : bundleSizes.map(sizeName => {
    const variantsBySpecificSize = getAllSelectedApparelsSpecificSizeSelector(sizeName)(state);
    const calculationData = variantsBySpecificSize.reduce((acc, variant) => {
      if (variant) {
        const {
          quantityPerBundle,
          quantity,
          colorValue,
          inReserveStockQuantity,
          inProductionQuantity,
          inReserveProductionQuantity
        } = variant;
        acc.push({
          colorValue,
          quantityPerBundle,
          inStockQuantity: quantity || 0,
          inStockReservedQuantity: inReserveStockQuantity || 0,
          inProductionQuantity: inProductionQuantity || 0,
          inProductionReservedQuantity: inReserveProductionQuantity || 0
        });
      }
      return acc;
    }, []);
    const {
      inProduction,
      inStock
    } = calculateMaxPossibleQuantityOfBundles(calculationData);
    return {
      sizeName,
      inStockOverall: inStock,
      inProductionOverall: inProduction
    };
  });
});
export const bundleCalculatedQuantitiesForApparelsInBundleSelector = createSelector(commonSelectedProductsSizesWithCalculatedQuantitiesSelector, swagBundlesSelectedProductsOnlyApparelsSelector, (commonBundleSizesWithCalculatedQuantities, swagBundlesSelectedProductsOnlyApparels) => {
  if (!swagBundlesSelectedProductsOnlyApparels.length) {
    return;
  }
  const uniqueApparelsMap = new Map(swagBundlesSelectedProductsOnlyApparels.map(({
    parentId,
    colorValue
  }) => [JSON.stringify({
    parentId,
    colorValue
  }), {
    parentId,
    colorValue
  }]));
  const additionalProductData = uniqueApparelsMap.size === 1 ? {
    colorValue: swagBundlesSelectedProductsOnlyApparels[0].colorValue,
    productId: String(swagBundlesSelectedProductsOnlyApparels[0].parentId)
  } : {};
  return commonBundleSizesWithCalculatedQuantities.reduce((acc, {
    inProductionOverall,
    inStockOverall
  }) => _objectSpread({}, acc, {
    inStockQuantity: acc.inStockQuantity + inStockOverall,
    inProductionQuantity: acc.inProductionQuantity + inProductionOverall
  }, additionalProductData), {
    inStockQuantity: 0,
    inProductionQuantity: 0,
    quantityPerBundle: 1
  });
});
export const selectedProductsDataForCalculationBundlesQuantitySelector = createSelector(swagBundlesSelectedProductsOnlyNonApparelsSelector, bundleCalculatedQuantitiesForApparelsInBundleSelector, state => state, (selectedNonApparels, calculatedApparels, state) => {
  let calculationData = [];
  calculationData = [...calculationData, ...selectedNonApparels.map(({
    quantityPerBundle,
    quantity,
    colorValue,
    inReserveStockQuantity,
    inProductionQuantity,
    inReserveProductionQuantity,
    parentId
  }) => {
    return {
      productId: String(parentId),
      colorValue,
      quantityPerBundle,
      inStockQuantity: quantity || 0,
      inStockReservedQuantity: inReserveStockQuantity || 0,
      inProductionQuantity: inProductionQuantity || 0,
      inProductionReservedQuantity: inReserveProductionQuantity || 0
    };
  }), ...(calculatedApparels ? [calculatedApparels] : [])];
  const preSelectedPackage = preSelectedPackagingProductSelector(state);
  if (preSelectedPackage) {
    const preSelectedPackageCalculationData = {
      productId: String(preSelectedPackage._id),
      colorValue: preSelectedPackage.variants[0].colorValue,
      quantityPerBundle: 1,
      inStockQuantity: preSelectedPackage.inStock || 0,
      inStockReservedQuantity: preSelectedPackage.inReserveStockQuantity,
      inProductionQuantity: preSelectedPackage.inProduction || 0,
      inProductionReservedQuantity: preSelectedPackage.inReserveProductionQuantity
    };
    calculationData = [...calculationData, preSelectedPackageCalculationData];
  }
  const preSelectedNotecard = preSelectedNotecardProductSelector(state);
  if (preSelectedNotecard) {
    const preSelectedNotecardCalculationData = {
      productId: String(preSelectedNotecard._id),
      colorValue: preSelectedNotecard.variants[0].colorValue,
      quantityPerBundle: 1,
      inStockQuantity: preSelectedNotecard.inStock || 0,
      inStockReservedQuantity: preSelectedNotecard.inReserveStockQuantity,
      inProductionQuantity: preSelectedNotecard.inProduction || 0,
      inProductionReservedQuantity: preSelectedNotecard.inReserveProductionQuantity
    };
    calculationData = [...calculationData, preSelectedNotecardCalculationData];
  }
  return calculationData;
});